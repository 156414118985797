//Colors:
$margin-bg: #DCDCDC;
$main-bg: #303030;
$main-border-color: #BEBEBE;
$btn-color-green: darkgreen;
$btn-color-green2: rgb(16, 184, 111);
$star-color: #ffc107;
$heart-color1: #888888;
$heart-color2: #ff3300;

$btn-color-red: #AC2626;
$btn-color-bluish: #297979;
$btn-color-bluish2: #448F8F;

$footer-bg: #383838;
$footer-bg2: #707070;

//Fonts:
$arcade-font: 'Courier New', Courier, monospace;
$dos-font: 'Courier New', Courier, monospace;

//Sizes:
$max-width1: 1200px;
$max-width2: 1300px;

//Random Numbers
$randomNum1: random($limit: 5) + 3;
$randomNum1X: random($limit: 100) - 50;
$randomNum1Y: random($limit: 100) - 50;
$randomNum2: random($limit: 5) + 3;
$randomNum2X: random($limit: 100) - 50;
$randomNum2Y: random($limit: 100) - 50;
$randomNum3: random($limit: 5) + 3;
$randomNum3X: random($limit: 100) - 50;
$randomNum3Y: random($limit: 100) - 50;
$randomNum4: random($limit: 5) + 3;
$randomNum4X: random($limit: 100) - 50;
$randomNum4Y: random($limit: 100) - 50;
$randomNum5: random($limit: 5) + 3;
$randomNum5X: random($limit: 100) - 50;
$randomNum5Y: random($limit: 100) - 50;
$randomNum6: random($limit: 5) + 3;
$randomNum6X: random($limit: 100) - 50;
$randomNum6Y: random($limit: 100) - 50;
