@import 'variables.scss';


@mixin gamesContainer1300{
    // width: 100%;
    max-width: $max-width2;
    margin: auto;
    border: none;
    box-shadow: none;
}
